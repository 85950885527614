
export default {
  props: {
    channel: {
      type: String,
      default: ''
    },
    callBacks: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    setTour: false,
    steps_web: [],
    steps_mobile: []
  }),
  mounted () {
    this.setTourData()
  },
  methods: {
    async setTourData () {
      const tour = localStorage.getItem(`modoc_${this.channel}_tour`)
      if (!tour) {
        await this.$store.dispatch('public/getTour')
          .then((res) => {
            const data = res
            if (!data || data.length === 0) { return }
            data.map((obj) => {
              if (obj.channel && obj.channel === this.channel && obj.lang === this.$store.state.public.lang) {
                if (this.$store.state.auth.user.isLogin) {
                  if (['navlogin', 'navregister'].includes(obj.boxid)) {} else {
                    this['steps_' + obj.device].push({
                      target: `#${obj.boxid}`,
                      header: {
                        title: obj.header_msg
                      },
                      content: obj.body_msg
                    })
                  }
                } else {
                  this['steps_' + obj.device].push({
                    target: `#${obj.boxid}`,
                    header: {
                      title: obj.header_msg
                    },
                    content: obj.body_msg
                  })
                }
              }
            })
            this.setTour = true
          })
        if (this.setTour) {
          await setTimeout(() => {
            this.$tours.pageTour.start()
          }, 1000)
        }
      }
    },
    getStorageChannel (step) {
      return localStorage.setItem(`modoc_${this.channel}_tour`, true)
    }
  }
}
