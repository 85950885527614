
export default {
  data () {
    return {
      search: '',
      tags: [
        { name: 'Mobile Bekas', type: '' },
        { name: 'Modifikasi', type: '' },
        { name: 'Mobil Listrik', type: '' }
      ]
    }
  },
  methods: {
    onSubmit (type = 'all') {
      if (this.search) {
        const site = `https://modoc.id/search?keyword=${this.search}&type=${type}`
        window.open(site, '_blank')
        // this.$router.push(this.localeLocation(`/search?keyword=${url}&type=${type}`))
      }
    }
  }
}
