
export default {
  data: () => {
    return {
      category_list: [],
      top_users: [],
      menu: {
        brand: [],
        cars: [],
        vehicles: []
      },
      popular_news: [],
      popular_ask: [],
      popular_community: []
    }
  },
  mounted () {
    this.fetchTopUsers()
    this.fetchPopularNews()
    this.fetchTopQuestions()
    this.fetchTopCommunity()
  },
  methods: {
    async fetchTopUsers () {
      await this.$store.dispatch('public/getTopUserToday')
        .then((res) => {
          this.top_users = res.top_users
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
    async fetchPopularNews () {
      const params = 'popular?limit=4'
      await this.$store.dispatch('public/getNews', params)
        .then((res) => {
          this.popular_news = res.news_popular
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
    async fetchTopQuestions () {
      await this.$store.dispatch('public/getQuestions', 'trending?limit=3')
        .then((res) => {
          this.popular_ask = res.trending_questions
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
    async fetchTopCommunity () {
      await this.$store.dispatch('public/getCommunities', 'popular?limit=4')
        .then((res) => {
          this.popular_community = res
        })
        .catch((err) => {
          throw new Error(err)
        })
    }
  }
}
