
export default {
  name: 'PagesError',
  props: {
    err: {
      type: Object,
      default: () => {}
    }
  }
}
